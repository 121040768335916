'use client'
import React, { useEffect, useState } from "react";

export interface ScrollToTopProps {
}

const TidioScriptLoader: React.FC<ScrollToTopProps> = () => {
  const [isSet, setIsSet] = useState<boolean>(false)
  useEffect(() => {
    if (typeof window !== "undefined" && !isSet) {
      localStorage.removeItem('theme')
      localStorage.theme = 'dark'
      document.documentElement.classList.add('dark')
      document.documentElement.classList.remove('light')
      window.scrollTo(0, 0);
      const tidioScript = document.createElement('script');
      tidioScript.src = '//code.tidio.co/6mcfhrqttqe5rsqq5beiku5uxtn7yyqf.js';
      document.body.appendChild(tidioScript);
      setIsSet(true);
    }
  }, []);

  return null;
};

export default TidioScriptLoader;
